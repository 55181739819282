import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const SidePhotoComponent = styled.div`
  width     : 100%;
  align-self: flex-start;
  animation : ${fade} 2s ease;
`;

const SidePhoto = ({ children }) => {
  return <SidePhotoComponent>{children}</SidePhotoComponent>;
};

export default SidePhoto;
